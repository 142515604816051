/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {

        // All pages
        'common': {
            init: function () {

                // Navigation
                var navMenu = $('#nav.main__nav');
                var navOpen = $('#nav__open');
                var navClose = $('#nav__close');
                var ulMenu = $('#menu-main');
                ulMenu.attr('aria-haspopup', 'true');
                ulMenu.attr('tabindex', '-1');

                $('body').focusin(function (e) {
                    if ($(navMenu)[0] !== undefined && !$.contains($(navMenu)[0], document.activeElement)) {
                        navClose.trigger('click');
                    }
                });

                // keyboard navigation
                navMenu.on('keydown', function (event) {
                    // keyCode -> http://www.javascripter.net/faq/keycodes.htm
                    // 27 = esc
                    if (event.keyCode === 27) {
                        navClose.trigger('click');
                    }
                });

                // open navigation
                navOpen.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.addClass('open');
                        $('html, body').addClass('main__nav-opened');
                    }
                );

                // close navigation
                navClose.click(
                    function () {
                        navOpen.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navClose.attr('aria-expanded', navClose.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        ulMenu.attr('aria-expanded', navOpen.attr('aria-expanded') === 'false' ? 'true' : 'false');
                        navMenu.removeClass('open');
                        $('html, body').removeClass('main__nav-opened');
                    }
                );

                // toggle sub-menu
                var menuMainItems = document.querySelectorAll('.main_menu li.menu-item-has-children');
                
                function setAria(){
                    if ($(window).width() > 820) {

                        menuMainItems.forEach(function(item) {
                            item.querySelector('a').setAttribute('aria-expanded', "true");
                            item.querySelector('a').setAttribute('tabindex', "-1");
                        });

                    } else {
                        menuMainItems.forEach(function(item) {
                            if ( item.classList.contains('open') ) {
                    			item.querySelector('a').setAttribute('aria-expanded', "true");
                                item.querySelector('a').removeAttribute('tabindex');
                    		} else {
                    			item.querySelector('a').setAttribute('aria-expanded', "false");
                                item.querySelector('a').removeAttribute('tabindex');
                    		}
                        });
                    }
                }
                setAria();
                $(window).resize(function() { setAria(); });

                

                Array.prototype.forEach.call(menuMainItems, function (el, i) {
                    el.querySelector('a').addEventListener("click", function (event) {

                        // tolgo class open da tutti gli a
                        menuMainItems.forEach(function (item) {
                            item.classList.remove('open');
                        });

                        if (this.parentNode.classList.contains('open')) {
                            this.parentNode.classList.remove('open');
                        } else {
                            this.parentNode.classList.add('open');
                        }
                        event.preventDefault();

                        return false;
                    });

                    // focus
                    el.addEventListener("focusin", function (event) {
                        this.classList.add('open');
                    });
                    el.addEventListener("focusout", function (event) {
                        this.classList.remove('open');
                    });
                });

                
                var menuHeaderItems = document.querySelectorAll('.header_menu_mobile li.menu-item-has-children');
                Array.prototype.forEach.call(menuHeaderItems, function(el, i){

                    // click
                	el.querySelector('a').addEventListener("click",  function(event){
                		if ( this.parentNode.classList.contains('open') ) {
                            this.parentNode.classList.remove('open');
                			this.setAttribute('aria-expanded', "false");
                		} else {
                			this.parentNode.classList.add('open');
                            this.setAttribute('aria-expanded', "true");
                		}
                		event.preventDefault();
                		return false;
                	});
                });

                // AOS - animate on scroll
                // setting
                AOS.init({
                    offset: 120,
                    once: true,
                });

                document.querySelectorAll('img')
                    .forEach(function (img) {
                        img.addEventListener('load', function () {
                            AOS.refresh();
                        });
                    });

                /*\ !! SLICK SLIDER !! \*/

                //Slider pagina lavorazioni
                var gallerySliderImage = $('.gallery--image');

                gallerySliderImage.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    adaptiveHeight: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 0, 
                    
                });
                
                var totalSlideCaratteristiche = $('.gallery--image .slick-slide:not(.slick-cloned)').length;

                // Torno indietro alla fine dello slide
                $('.gallery--image').on('beforeChange', function (event, slick, currentSlide, nextSlide) {

                    var prevArrow = document.querySelectorAll('.gallery--image .pp2');
                    var nextArrow = document.querySelectorAll('.gallery--image .nn2');

                    // disabilito arrow se arrivo alla fine o sono all'inizio
                    if (nextSlide > 0) {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });

                    } else {
                        prevArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    }

                    if (nextSlide == totalSlideCaratteristiche - 1) {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('enabled')) {
                                arrow.classList.remove('enabled');
                            }

                            arrow.classList.add('disabled');
                        });
                    } else {
                        nextArrow.forEach(function (arrow) {
                            if (arrow.classList.contains('disabled')) {
                                arrow.classList.remove('disabled');
                            }

                            arrow.classList.add('enabled');
                        });
                    }
                });

                

                /**
                 * AUTENTICAZIONE PER REST API
                 */
                // Set state object with values that are changed programatically
                var state = {
                    loggedIn: false,
                    restUrl: "/wp-json/",
                    token: "wp-token",
                };

                function setState(toSet, newValue) {
                    state[toSet] = newValue;
                }

                // Controllo se l'utente è già autenticato o devo inizializzare il login
                if (cookie.get(state.token) === undefined) {
                    //logout();
                    //initLogin();
                } else {
                    //login();
                    //initLogout();
                }

                // setto variabile loggedIn a false
                function logout() {
                    setState("loggedIn", false);
                    console.log(state);
                }

                // setto variabile loggedIn a false
                function login() {
                    setState("loggedIn", true);
                    console.log(state);
                }

                // processo di login
                function initLogin() {
                    // recupero form del login
                    var formLogin = document.querySelector('#gform_0');
                    if (formLogin) {
                        // creo listener sul submit del form
                        formLogin.addEventListener('submit', function (e) {
                            e.preventDefault();

                            var creds = {
                                username: document.querySelector('#input_1').value,
                                password: document.querySelector('#input_2').value
                            }; // 1

                            /* var creds = {
                                username: 'system',
                                password: 'Archi15media',
                            };  */

                            if (creds.username !== "" && creds.password !== "") {
                                // Eseguo request per autenticarmi sulle REST API di Wordpress
                                axios({
                                    method: "post",
                                    url: state.restUrl + "api/v1/token",
                                    data: 'username=' + creds.username + '&password=' + creds.password,
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded"
                                    }
                                }) // 2
                                    .then(function (response) {
                                        console.log(response, response.status);
                                        if (200 === response.status) {
                                            console.log(response.data);
                                            cookie.set(state.token, response.data.jwt_token, {
                                                expires: 1,
                                                secure: false,
                                                path: '/',
                                            }); // 3

                                            if (cookie.get('wp-token') !== undefined) {
                                                // faccio il submit del form
                                                formLogin.submit();
                                            }

                                        } else {
                                            // Eseguito quando ricevo una reponse con status != 200 
                                            alert("Login failed, please check credentials and try again!");
                                        }
                                    }) // 5
                                    .catch(function (error) {
                                        // Loggo l'errore attuale
                                        console.error(error);

                                        // eseguo comunque il submit per mostrare l'errore di Wordpress all'utente
                                        formLogin.submit();
                                    });
                            }
                        });
                    }
                }

                // processo di logout
                function initLogout() {
                    var logoutLink = document.querySelector('.logout a');

                    if (logoutLink) {
                        logoutLink.addEventListener('click', function (e) {
                            e.preventDefault();
                            cookie.removeSpecific(state.token, {
                                expires: 1,
                                secure: false,
                                path: '/',
                            });

                            logout();

                            // eseguo redirect per logout di Wordpress
                            var logoutUrl = e.target.href;
                            document.location = logoutUrl;
                        });
                    }
                }

                /**
                 * FINE AUTENTICAZIONE
                 */

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },


        // Page template Homepage
        'page_template_homepage': {
            init: function () {
                
            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },


        // Page template Prodotti
        'page_template_prodotti': {
            init: function () {
                var boxElements = document.querySelectorAll(".box__header");
                
                boxElements.forEach(function(box) {
                    box.addEventListener("click", function () {
                        var boxContent = this.closest(".box").querySelector(".box__content");
                        var arrow = this.querySelector(".arrow"); 

                        if (boxContent.style.display === "none" || boxContent.style.display === "") {
                            boxContent.style.display = "block";
                            arrow.classList.add("rotate");
                        } else {
                            boxContent.style.display = "none";
                            arrow.classList.remove("rotate");
                        }
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired, after page specific JS is fired
                //gsap.registerPlugin(CSSRulePlugin);
            }
        },

        


    };// Sage

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };// UTIL

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


